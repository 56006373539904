import Vue from 'vue';
import VueRouter from 'vue-router';
import SingleProduct from "./components/SingleProduct.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [
        { 
            path: '/product-detail/:id', 
            component: SingleProduct 
        }
    ]
});

export default router;