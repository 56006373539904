<template>
    <div>
        <section id="product-detail" class="product-detail">
            <div class="container-fluid main-content">
                <div class="row box-content">
                    <div class="col-12 col-lg-6 col-md-12 col-sm-12 img-content">
                        <!-- <div class="main-img-product" style="background-image: url('/img/product/jewell/butani-image-combined.png');"></div> -->
                        
                        <!-- <div class="main-img-product" style="background-image: url('/img/product/jewell/jewell-4.png');"> -->
                        <template v-if="product.front.galleries">
                            <div v-for="(image, id) in product.front.galleries.slice(0,1)" :key="id" class="main-img-product" :style="`background-image: url('/storage/${image.src}');`">
                            
                            <!-- <div class="main-img-product" style="background-image: url('/img/product/jewell/ring-color-gold.png'), url('/img/product/jewell/stone-color-blue.png');" value-stone="blue" value-ring="gold"> -->
                                <!-- <div class="main-img-product-stone" style="background-image: url('/img/product/jewell/stone-color-blue.png');"></div> -->
                            </div>
                        </template>
                        <template v-else>
                            <div></div>
                        </template>
                        
                        <div class="sub-img-product" id="slider-sub-product">
                            <div v-for="(image, id) in product.front.galleries" :key="id" class="item">
                                <div class="img-sub-product" :style="`background-image: url('/storage/${image.src}');`"></div>
                            </div>
                            <!-- <div class="item">
                                <div class="img-sub-product" style="background-image: url('/img/product/jewell/jewell-2.png');"></div>
                            </div>
                            <div class="item">
                                <div class="img-sub-product" style="background-image: url('/img/product/jewell/jewell-3.png');"></div>
                            </div>
                            <div class="item">
                                <div class="img-sub-product" style="background-image: url('/img/product/jewell/jewell-1.png');"></div>
                            </div>
                            <div class="item">
                                <div class="img-sub-product" style="background-image: url('/img/product/jewell/jewell-2.png');"></div>
                            </div> -->
                        </div>

                    </div>
                    <div class="col-12 col-lg-6 col-md-12 col-sm-12 text-content">
                        <div class="title-content">
                            <span>{{ product.collection_name }}</span>
                            <!-- <h1>Ring - 18k Yellow Gold</h1> -->
                            <h1>{{ product.name }}</h1>
                        </div>
                        <div class="price-product">
                            <span>Starting Form ${{ product.price }}</span>
                        </div>
                        <!-- <div class="qty-product">
                            <span>Quantity :</span>
                            <div class="qty-value">
                                <div class="qty-item minus-qty">
                                    <i class="fas fa-minus"></i>
                                </div>
                                <div class="qty-item value-qty" data-value="0">
                                    <span>0</span>
                                </div>
                                <div class="qty-item plus-qty">
                                    <i class="fas fa-plus"></i>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="size-product">
                            <div class="title-size">
                                <span>Size :</span>
                                <a href="">Size Chart</a>
                            </div>
                            <div class="box-input">
                                <select>
                                    <option>44</option>
                                </select>
                            </div>
                        </div> -->

                        <div class="color-product">
                            <span>Colors :</span>
                            <div class="wrapper-img">
                                <div v-for="(image, index) in product.options" :data-option="`${image.type}`" :key="index" v-on:click="getOption(image.type)" class="img-product" :class="{ 'active': image.type === option.toLowerCase() }"  :style="`background-image: url('/storage/${image.src}');`"></div>
                                <!-- <div v-for="(image, id) in product.images.options" :data-option="`${image.type}`" :key="id" v-on:click="getOption" class="img-product" :class="{ 'active': id === 0 }" :style="`background-image: url('${image.src}');`"></div> -->
                                <!-- <div class="img-product" style="background-image: url('/img/product/jewell/jewell-color-2.png');"></div> -->
                                <!-- <div class="img-product" style="background-image: url('/img/product/jewell/jewell-color-3.png');"></div> -->
                            </div>
                        </div>

                        <!-- <div class="color-product">
                            <span>Colors Stone :</span>
                            <div class="wrapper-img colors-stone">
                                <div class="img-product stone active" style="background-image: url('/img/product/jewell/stone-color-blue.png');" data-stone="blue"></div>
                                <div class="img-product stone" style="background-image: url('/img/product/jewell/stone-color-red.png');" data-stone="red"></div>
                                <div class="img-product stone" style="background-image: url('/img/product/jewell/stone-color-white.png');" data-stone="white"></div>
                            </div>
                        </div> -->

                        <!-- <div class="color-product">
                            <span>Rings :</span>
                            <div class="wrapper-img colors-ring">
                                <div class="img-product ring active" style="background-image: url('/img/product/jewell/ring-color-gold.png');" data-ring="gold"></div>
                                <div class="img-product ring" style="background-image: url('/img/product/jewell/ring-color-orange.png');" data-ring="orange"></div>
                                <div class="img-product ring" style="background-image: url('/img/product/jewell/ring-color-silver.png');" data-ring="silver"></div>
                            </div>
                        </div> -->

                        <div class="wrapper-button">
                            <button data-toggle="modal" data-target="#exampleModal" class="btn font-button-17 button-fill fill-dark">
                                <span style="margin-right: 10px; font-weight: 700;">Enquiry form</span>
                                <!-- <img src="/img/product/shopping-bag.png" alt=""> -->
                            </button>
                        </div>

                        <div class="wrapper-description">
                            <div class="menu-wrapper">
                                <div class="men-wrap description active" data-detail="description">
                                    <span>Description</span>
                                </div>
                                <div class="men-wrap item-details" data-detail="item-details">
                                    <span>Item Details</span>
                                </div>
                            </div>
                            <div class="wrapper-detail">
                                <div class="detail-box description-detail active">
                                    <div v-html="product.description"></div>
                                </div>
                                <div class="detail-box item-details-detail">
                                    <div v-html="product.item_detail"></div>
                                </div>
                            </div>
                        </div>

                        <div class="wrapper-other">
                            <div class="wrapper-box find">
                                <a href="">
                                    <span>Find A Boutique</span>
                                    <i class="fas fa-map-marker-alt"></i>
                                </a>
                            </div>
                            <div class="wrapper-box share">
                                <a href="">
                                    <span>Share</span>
                                    <i class="fas fa-share-alt"></i>
                                </a>
                            </div>
                            <div class="wrapper-box contact-us">
                                <a href="">
                                    <span>Contact Us</span>
                                    <i class="fas fa-phone-alt"></i>
                                </a>
                            </div>

                            <div class="wrapper-box add-wishlist">
                                <a href="">
                                    <span>Add To Wishlist</span>
                                    <i class="fas fa-heart"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- Modal -->
        <section id="modal-form" class="modal-bespoke">
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="container-fluid p-0">
                                <div class="row box-content menu-box-content online-registration form-template">
                                    <div class="col-md-12 content">
                                        <div class="title-content">
                                            <h2>BESPOKE ENQUIRY FORM</h2>
                                        </div>
                                        <form class="row content-box">
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>First Name</label>
                                                    <input type="text" name="first_name" placeholder="Your First Name">
                                                </div>
                                            </div>
    
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Last Name</label>
                                                    <input type="text" name="last_name" placeholder="Your Last Name">
                                                </div>
                                            </div>
    
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Email Address</label>
                                                    <input type="text" name="email_address" placeholder="Your Email Address">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Phone Number</label>
                                                    <input type="text" name="phone_number" placeholder="Your Phone Number">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Interest In ? </label>
                                                        <div class="d-flex mt-2">
                                                            <span class="font-20 text-capitalize">{{ product.name }} - {{ option }}</span>
                                                            <!-- <input type="text" placeholder="Interest In" readonly v-model="option"> -->
                                                        </div>
                                                    <!-- <select>
                                                        <option>Ring</option>
                                                        <option>Tester</option>
                                                        <option>Tester</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Style interested in</label>
                                                    <select>
                                                        <option>Option 1</option>
                                                        <option>Option 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Shape interested in</label>
                                                    <select>
                                                        <option>Option 1</option>
                                                        <option>Option 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Request An Appointment By*</label>
                                                    <select>
                                                        <option>Online Call</option>
                                                        <option>Tester</option>
                                                        <option>Tester</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>Request Date for Appointment</label>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <select>
                                                                <option>Your Date/Time</option>
                                                                <option>Tester</option>
                                                                <option>Tester</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form">
                                                    <label>At*</label>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <select id="day">											
                                                            </select>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <select id="mounth">
                                                            </select>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <select id="years">
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="box-form">
                                                    <label>Message*</label>
                                                    <input type="text" name="Message" placeholder="Your Message">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <!-- <label class="form-check-label" for="exampleCheck1">Check me out</label> -->
                                                    <span class="req-text">Sign up for our newsletter</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="box-form button">
                                                    <button type="submit" class="btn font-button-17 button-border purple fw-700">SUBMIT <i class="fas fa-chevron-right"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import collections from "../collections.json";
    export default {
        props: ['collections'],
        name: 'SingleProduct',
        data() {
            return {
                product: this.collections.products,
                option: this.collections.products.front.color,
            }
        },
        mounted() {
            console.log(this.product);
            // this.product = this.findById(this.$route.params.id);
        },
        methods: {
            // findById(id) {
            //     for(let i = 0; i < collections.products.length; i++ ) 
            //     {
            //         if(id === collections.products[i].id) return collections.products[i];

            //     }
            //     return null;
            // },
            getOption(type) {
                // return this.option = event.target.getAttribute('data-option');
                return this.option = type;
            },
        }
    }
</script>
