var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { attrs: { id: "filter-box" } }, [
      _c("div", { staticClass: "container-fluid main-content" }, [
        _c("div", { staticClass: "row box-content" }, [
          _c("div", { staticClass: "col-12 col-md-12 col-sm-12 content" }, [
            _c("div", { staticClass: "wrapper-filter" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "box-filter" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCollection,
                        expression: "selectedCollection"
                      }
                    ],
                    attrs: { id: "collection" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedCollection = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "Bloom" } }, [
                      _vm._v("Bloom")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Metropolitan" } }, [
                      _vm._v("Metropolitan")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Nova" } }, [
                      _vm._v("Nova")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Perennial" } }, [
                      _vm._v("Perennial")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Unlock" } }, [
                      _vm._v("Unlock")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box-filter" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCategory,
                        expression: "selectedCategory"
                      }
                    ],
                    attrs: { id: "category" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedCategory = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "All", selected: "" } }, [
                      _vm._v("All Category")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Bangles" } }, [
                      _vm._v("Bangles")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Earrings" } }, [
                      _vm._v("Earrings")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Necklaces" } }, [
                      _vm._v("Necklaces")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Rings" } }, [
                      _vm._v("Rings")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box-filter" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedColor,
                        expression: "selectedColor"
                      }
                    ],
                    attrs: { id: "color" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedColor = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "All", selected: "" } }, [
                      _vm._v("All Color")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Bronze" } }, [
                      _vm._v("Bronze")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Gold" } }, [
                      _vm._v("Gold")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Silver" } }, [
                      _vm._v("Silver")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "box-filter" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPrice,
                        expression: "selectedPrice"
                      }
                    ],
                    attrs: { id: "price" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedPrice = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "All", selected: "" } }, [
                      _vm._v("All Price")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "3.440" } }, [
                      _vm._v("3.440")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "1.000" } }, [
                      _vm._v("1.000")
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "layout-rings-collection product-container",
        attrs: { id: "ring-product" }
      },
      [
        _c("div", { staticClass: "container-fluid main-content" }, [
          _c("div", { staticClass: "row box-content p-0" }, [
            _c("div", { staticClass: "col-12 col-md-12 col-sm-12 content" }, [
              _c("div", { staticClass: "title-product d-none" }, [
                _c("h1", [_vm._v(_vm._s(_vm.selectedCategory))])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "product-banner",
                  staticStyle: {
                    "background-image":
                      "url('/img/background/collection-bg.jpg')"
                  }
                },
                [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c("div", { staticClass: "col-12 col-md-5" }, [
                      _c("div", { staticClass: "text-area" }, [
                        _c("h2", { staticClass: "product-banner__title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.selectedCollection +
                                " " +
                                _vm.selectedCategory
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et."
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-area product-area-collection" },
                [
                  _c(
                    "div",
                    { staticClass: "row box-ring-product product-grid" },
                    [
                      _vm._l(_vm.productsToShow, function(productIndex, id) {
                        return productIndex <= _vm.filteredResult.length
                          ? [
                              _c("product-card", {
                                key: id,
                                attrs: {
                                  "product-index": productIndex,
                                  product: _vm.filteredResult
                                }
                              }),
                              _vm._v(" "),
                              productIndex === 6
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "col-12 col-sm-6 feature-img"
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/img/background/deco-img-feature.jpg",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 text-center button-box mt-5" },
                      [
                        _vm.productsToShow < _vm.filteredResult.length
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn font-button-17 button-border purple",
                                on: {
                                  click: function($event) {
                                    _vm.productsToShow += 4
                                  }
                                }
                              },
                              [_vm._v("Load More")]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-filter text-content" }, [
      _c("span", [_vm._v("Filter by :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-7" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: "/img/background/collection-deco-rings.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }