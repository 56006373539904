var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "product-detail", attrs: { id: "product-detail" } },
      [
        _c("div", { staticClass: "container-fluid main-content" }, [
          _c("div", { staticClass: "row box-content" }, [
            _c(
              "div",
              {
                staticClass: "col-12 col-lg-6 col-md-12 col-sm-12 img-content"
              },
              [
                _vm.product.front.galleries
                  ? _vm._l(_vm.product.front.galleries.slice(0, 1), function(
                      image,
                      id
                    ) {
                      return _c("div", {
                        key: id,
                        staticClass: "main-img-product",
                        style:
                          "background-image: url('/storage/" + image.src + "');"
                      })
                    })
                  : [_c("div")],
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "sub-img-product",
                    attrs: { id: "slider-sub-product" }
                  },
                  _vm._l(_vm.product.front.galleries, function(image, id) {
                    return _c("div", { key: id, staticClass: "item" }, [
                      _c("div", {
                        staticClass: "img-sub-product",
                        style:
                          "background-image: url('/storage/" + image.src + "');"
                      })
                    ])
                  }),
                  0
                )
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-lg-6 col-md-12 col-sm-12 text-content"
              },
              [
                _c("div", { staticClass: "title-content" }, [
                  _c("span", [_vm._v(_vm._s(_vm.product.collection_name))]),
                  _vm._v(" "),
                  _c("h1", [_vm._v(_vm._s(_vm.product.name))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price-product" }, [
                  _c("span", [
                    _vm._v("Starting Form $" + _vm._s(_vm.product.price))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "color-product" }, [
                  _c("span", [_vm._v("Colors :")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "wrapper-img" },
                    _vm._l(_vm.product.options, function(image, index) {
                      return _c("div", {
                        key: index,
                        staticClass: "img-product",
                        class: {
                          active: image.type === _vm.option.toLowerCase()
                        },
                        style:
                          "background-image: url('/storage/" +
                          image.src +
                          "');",
                        attrs: { "data-option": "" + image.type },
                        on: {
                          click: function($event) {
                            return _vm.getOption(image.type)
                          }
                        }
                      })
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "wrapper-description" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "wrapper-detail" }, [
                    _c(
                      "div",
                      { staticClass: "detail-box description-detail active" },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.product.description)
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "detail-box item-details-detail" },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.product.item_detail)
                          }
                        })
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(2)
              ]
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "modal-bespoke", attrs: { id: "modal-form" } },
      [
        _c(
          "div",
          {
            staticClass: "modal fade",
            attrs: {
              id: "exampleModal",
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "exampleModalLabel",
              "aria-hidden": "true"
            }
          },
          [
            _c(
              "div",
              { staticClass: "modal-dialog", attrs: { role: "document" } },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "container-fluid p-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row box-content menu-box-content online-registration form-template"
                        },
                        [
                          _c("div", { staticClass: "col-md-12 content" }, [
                            _vm._m(3),
                            _vm._v(" "),
                            _c("form", { staticClass: "row content-box" }, [
                              _vm._m(4),
                              _vm._v(" "),
                              _vm._m(5),
                              _vm._v(" "),
                              _vm._m(6),
                              _vm._v(" "),
                              _vm._m(7),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c("div", { staticClass: "box-form" }, [
                                  _c("label", [_vm._v("Interest In ? ")]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "d-flex mt-2" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "font-20 text-capitalize"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.product.name) +
                                            " - " +
                                            _vm._s(_vm.option)
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._m(8),
                              _vm._v(" "),
                              _vm._m(9),
                              _vm._v(" "),
                              _vm._m(10),
                              _vm._v(" "),
                              _vm._m(11),
                              _vm._v(" "),
                              _vm._m(12),
                              _vm._v(" "),
                              _vm._m(13),
                              _vm._v(" "),
                              _vm._m(14),
                              _vm._v(" "),
                              _vm._m(15)
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper-button" }, [
      _c(
        "button",
        {
          staticClass: "btn font-button-17 button-fill fill-dark",
          attrs: { "data-toggle": "modal", "data-target": "#exampleModal" }
        },
        [
          _c(
            "span",
            { staticStyle: { "margin-right": "10px", "font-weight": "700" } },
            [_vm._v("Enquiry form")]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "men-wrap description active",
          attrs: { "data-detail": "description" }
        },
        [_c("span", [_vm._v("Description")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "men-wrap item-details",
          attrs: { "data-detail": "item-details" }
        },
        [_c("span", [_vm._v("Item Details")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper-other" }, [
      _c("div", { staticClass: "wrapper-box find" }, [
        _c("a", { attrs: { href: "" } }, [
          _c("span", [_vm._v("Find A Boutique")]),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-map-marker-alt" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-box share" }, [
        _c("a", { attrs: { href: "" } }, [
          _c("span", [_vm._v("Share")]),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-share-alt" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-box contact-us" }, [
        _c("a", { attrs: { href: "" } }, [
          _c("span", [_vm._v("Contact Us")]),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-phone-alt" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper-box add-wishlist" }, [
        _c("a", { attrs: { href: "" } }, [
          _c("span", [_vm._v("Add To Wishlist")]),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-heart" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-content" }, [
      _c("h2", [_vm._v("BESPOKE ENQUIRY FORM")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("First Name")]),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "text",
            name: "first_name",
            placeholder: "Your First Name"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Last Name")]),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "text",
            name: "last_name",
            placeholder: "Your Last Name"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Email Address")]),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "text",
            name: "email_address",
            placeholder: "Your Email Address"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Phone Number")]),
        _vm._v(" "),
        _c("input", {
          attrs: {
            type: "text",
            name: "phone_number",
            placeholder: "Your Phone Number"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Style interested in")]),
        _vm._v(" "),
        _c("select", [
          _c("option", [_vm._v("Option 1")]),
          _vm._v(" "),
          _c("option", [_vm._v("Option 2")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Shape interested in")]),
        _vm._v(" "),
        _c("select", [
          _c("option", [_vm._v("Option 1")]),
          _vm._v(" "),
          _c("option", [_vm._v("Option 2")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Request An Appointment By*")]),
        _vm._v(" "),
        _c("select", [
          _c("option", [_vm._v("Online Call")]),
          _vm._v(" "),
          _c("option", [_vm._v("Tester")]),
          _vm._v(" "),
          _c("option", [_vm._v("Tester")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Request Date for Appointment")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("select", [
              _c("option", [_vm._v("Your Date/Time")]),
              _vm._v(" "),
              _c("option", [_vm._v("Tester")]),
              _vm._v(" "),
              _c("option", [_vm._v("Tester")])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("At*")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("select", { attrs: { id: "day" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("select", { attrs: { id: "mounth" } })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("select", { attrs: { id: "years" } })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "box-form" }, [
        _c("label", [_vm._v("Message*")]),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "text", name: "Message", placeholder: "Your Message" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: { type: "checkbox", id: "exampleCheck1" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "req-text" }, [
          _vm._v("Sign up for our newsletter")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "box-form button" }, [
        _c(
          "button",
          {
            staticClass: "btn font-button-17 button-border purple fw-700",
            attrs: { type: "submit" }
          },
          [_vm._v("SUBMIT "), _c("i", { staticClass: "fas fa-chevron-right" })]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }