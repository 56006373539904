<template>
    <div>
        <section id="filter-box">
            <div class="container-fluid main-content">
                <div class="row box-content">
                    <div class="col-12 col-md-12 col-sm-12 content">
                        <div class="wrapper-filter">
                            <div class="box-filter text-content">
                                <span>Filter by :</span>
                            </div>
                            <div class="box-filter">
                                <select id="collection" v-model="selectedCollection">
                                    <!-- <option value="All" selected>All Collection</option> -->
                                    <option value="Bloom">Bloom</option>
                                    <option value="Metropolitan">Metropolitan</option>
                                    <option value="Nova">Nova</option>
                                    <option value="Perennial">Perennial</option>
                                    <option value="Unlock">Unlock</option>
                                </select>
                            </div>
                            <div class="box-filter">
                                <select id="category" v-model="selectedCategory">
                                    <option value="All" selected>All Category</option>
                                    <option value="Bangles">Bangles</option>
                                    <option value="Earrings">Earrings</option>
                                    <option value="Necklaces">Necklaces</option>
                                    <option value="Rings">Rings</option>
                                </select>
                            </div>
                            <div class="box-filter">
                                <select id="color" v-model="selectedColor">
                                    <option value="All" selected>All Color</option>
                                    <option value="Bronze">Bronze</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Silver">Silver</option>
                                </select>
                            </div>
                            <div class="box-filter">
                                <select id="price" v-model="selectedPrice">
                                    <option value="All" selected>All Price</option>
                                    <option value="3.440">3.440</option>
                                    <option value="1.000">1.000</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- product -->
        <section id="ring-product" class="layout-rings-collection product-container">
            <div class="container-fluid main-content">
                <div class="row box-content p-0">
                    <div class="col-12 col-md-12 col-sm-12 content">
                        <div class="title-product d-none">
                            <h1>{{ selectedCategory }}</h1>
                        </div>
                        <div class="product-banner" style="background-image: url('/img/background/collection-bg.jpg');">
                            <div class="row align-items-center">
                                <div class="col-12 col-md-5">
                                    <div class="text-area">
                                        <h2 class="product-banner__title">{{ selectedCollection+' '+selectedCategory }}</h2>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <img src="/img/background/collection-deco-rings.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>

                        <div class="product-area product-area-collection">
                            <div class="row box-ring-product product-grid">

                                <template v-if="productIndex <= filteredResult.length" v-for="(productIndex, id) in productsToShow">
                                    <product-card :product-index="productIndex" :product="filteredResult" :key="id"></product-card>
                                    <div v-if="productIndex === 6" class="col-12 col-sm-6 feature-img">
                                        <img src="/img/background/deco-img-feature.jpg" alt="">
                                    </div>
                                </template>


                            </div>

                            <div class="row">
                                <div class="col-12 text-center button-box mt-5">
                                    <button class="btn font-button-17 button-border purple" v-if="productsToShow < filteredResult.length" @click="productsToShow += 4">Load More</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    // import collections from "../collections.json";
    import ProductCard from "./ProductCard";

    export default {
        props: ['collections'],
        components: {
            'product-card': ProductCard
        },
        data() {
            return {
                selectedCollection: 'Bloom',
                selectedCategory: 'All',
                selectedColor: 'All',
                selectedPrice: 'All',
                products: this.collections.products,
                // searchResult: this.products,
                productsToShow: 12,
                totalProduct: 0
            }
        },
        methods: {
            // search() {
            //     let filterCollection = this.selectedCollection,
            //     filterCategory = this.selectedCategory,
            //     filterColor = this.selectedColor,
            //     filterPrice = this.selectedPrice

            //     this.productsToShow = 9;

            //     this.searchResult = this.products.filter(function(product) {
            //         let filtered = true

            //         if (filterCollection && filterCollection.length > 0) {
            //             filtered = product.collection_name == filterCollection
            //         }

            //         if (filtered) {
            //             if (filterCategory && filterCategory.length > 0) {
            //             filtered = product.category == filterCategory
            //             }
            //         }

            //         if (filtered) {
            //             if (filterColor && filterColor.length > 0) {
            //             filtered = product.color == filterColor
            //             }
            //         }

            //         if (filtered) {
            //             if (filterPrice && filterPrice.length > 0) {
            //             filtered = product.price == filterPrice
            //             }
            //         }

            //         return filtered
            //     })
            // },
        },
        // mounted() {
        //     this.search();
        // },
        computed: {
            filteredResult: function() {

                var vm = this;

                let filterCollection = this.selectedCollection,
                filterCategory = this.selectedCategory,
                filterColor = this.selectedColor,
                filterPrice = this.selectedPrice

                // this.productsToShow = 9;

                return vm.products.filter(function(product) {
                    let filtered = true

                    if (filtered) {
                        if (filterCollection === 'All') {
                            return filtered
                        }
                        filtered = product.collection_name == filterCollection
                    }

                    if (filtered) {
                        if (filterCategory === 'All') {
                            return filtered
                        }
                        filtered = product.category == filterCategory
                    }

                    if (filtered) {
                        if (filterColor === 'All') {
                            return filtered
                        }
                        filtered = product.front.color == filterColor
                    }

                    if (filtered) {
                        if (filterPrice === 'All') {
                            return filtered
                        }
                        filtered = product.price == filterPrice
                    }

                    return filtered
                })
            }
        }
    }
</script>
