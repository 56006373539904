var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.product[_vm.productIndex - 1].id,
      class:
        "col-xl-3 col-lg-4 col-md-6 min-height-auto product product-" +
        _vm.product[_vm.productIndex - 1].id,
      attrs: {
        id: "product-" + _vm.product[_vm.productIndex - 1].id,
        "data-size": "0"
      },
      on: {
        mouseover: function($event) {
          _vm.isHovering = true
        },
        mouseout: function($event) {
          _vm.isHovering = false
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "product-card", class: { active: _vm.isHovering } },
        [
          _c("div", { staticClass: "image-area" }, [
            _c(
              "div",
              { staticClass: "image-area-slider" },
              _vm._l(
                _vm.product[_vm.productIndex - 1].front.thumbnails,
                function(imgUrl) {
                  return _c("img", {
                    key: imgUrl.src,
                    staticClass: "img-fluid",
                    attrs: {
                      src: "/storage/" + imgUrl.src,
                      width: "385",
                      height: "451"
                    }
                  })
                }
              ),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-area" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "text-area" }, [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(_vm.product[_vm.productIndex - 1].category)
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "desc" }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.product[_vm.productIndex - 1].name)
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btn-area" }, [
              _c(
                "a",
                {
                  staticClass: "btn button-border purple",
                  attrs: {
                    href:
                      "/collection/product-detail/" +
                      _vm.product[
                        _vm.productIndex - 1
                      ].front.color.toLowerCase() +
                      "/" +
                      _vm.product[_vm.productIndex - 1].id
                  }
                },
                [_vm._v("DISCOVER")]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "love-icon btn",
        attrs: {
          "love-product": "product-1",
          id: "love-product-1",
          "aria-status": "false"
        }
      },
      [
        _c("i", {
          staticClass: "fas fa-heart",
          staticStyle: { display: "none", "margin-left": "0" }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "far fa-heart",
          staticStyle: { "margin-left": "0" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }