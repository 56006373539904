<template>

    <div @mouseover="isHovering = true" 
        @mouseout="isHovering = false"
            :key="product[productIndex - 1].id" :class="`col-xl-3 col-lg-4 col-md-6 min-height-auto product product-${product[productIndex - 1].id}`" :id="`product-${product[productIndex - 1].id}`" data-size="0" >
        
        <div class="product-card" :class="{'active': isHovering}">
            
            <div class="image-area">
                <div class="image-area-slider">
                        <img v-for="imgUrl in product[productIndex - 1].front.thumbnails" :key="imgUrl.src" :src="`/storage/${imgUrl.src}`" width="385" height="451" class="img-fluid">
                    
                    <!-- <img v-for="image in product[productIndex - 1].front.thumbnails" :key="image.src" :src="`/storage/${image.src}`" alt="" class="img-fluid"> -->
                </div>
            </div>

            <!-- <div class="bottom-area-wrapper"> -->
                <div class="bottom-area">
                <button class="love-icon btn" love-product="product-1" id="love-product-1" aria-status="false">
                    <i class="fas fa-heart" style="display: none;margin-left: 0;"></i>
                    <i class="far fa-heart" style="margin-left: 0;"></i>
                </button>
                    <div class="text-area">
                        <h3 v-html="product[productIndex - 1].category "></h3>
                        <div class="desc">
                            <span v-html="product[productIndex - 1].name"></span>
                        </div>
                    </div>
                    <div class="btn-area">
                        <a :href="`/collection/product-detail/${product[productIndex - 1].front.color.toLowerCase()}/${product[productIndex - 1].id}`" class="btn button-border purple">DISCOVER</a>
                    </div>
                </div>
            <!-- </div> -->

        </div>

        <!-- <div class="img-box" :style="`background-image: url('${isHovering ? (product[productIndex - 1].front.thumbnails[1] && '/storage/'+product[productIndex - 1].front.thumbnails[1].src) || '/img/product/background-product.png' : '/storage/'+product[productIndex - 1].front.thumbnails[0].src}');`">

            <div class="text-box" :class="{'active-text-box': isHovering}">
                <div class="text-content">
                    <div class="top-text">
                        <div class="left-content">
                            <h6 v-html="product[productIndex - 1].name + ' - ' + product[productIndex - 1].front.color "></h6>
                        </div>
                        <div class="right-content">
                            <div class="love-icon" :love-product="`product-${product[productIndex - 1].id}`" :id="`love-product-${product[productIndex - 1].id}`" aria-status="false">
                                <i class="fas fa-heart"></i>
                                <i class="far fa-heart"></i>
                            </div>
                            <span class="price-product">${{ product[productIndex - 1].price }}</span>
                        </div>
                    </div>
                    <div class="description-text">
                        <div v-html="product[productIndex - 1].description.substring(0,73)+' ...' "></div>
                    </div>
                    <div class="bottom-text">
                        <div class="left-content">
                            <button id="add-bag" class="add-bag font-button-17" data-size="none" data-product="product-1" data-active="none" data-aria="false">
                                <img src="/img/product/shopping-bag.png" alt="">
                                <span>Add To Bag</span>
                            </button>
                        </div>
                        <div class="right-content">
                            <a :href="`/collection/product-detail/${product[productIndex - 1].front.color.toLowerCase()}/${product[productIndex - 1].id}`" style="text-decoration: none; color: unset;">
                                <span>See More Details <i class="fas fa-angle-right"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="size-box">
                <div class="title-box">
                    <span>Ring Size</span>
                </div>
                <div class="box-size-list">
                    <div class="list-size" data-size="4">
                        <span>4</span>
                    </div>
                    <div class="list-size" data-size="4">
                        <span>4 1/2</span>
                    </div>
                    <div class="list-size" data-size="5">
                        <span>5</span>
                    </div>
                    <div class="list-size" data-size="4">
                        <span>5 1/2</span>
                    </div>
                    <div class="list-size" data-size="6">
                        <span>6</span>
                    </div>
                    <div class="list-size" data-size="4">
                        <span>6 1/2</span>
                    </div>
                    <div class="list-size" data-size="7">
                        <span>7</span>
                    </div>
                    <div class="list-size" data-size="4">
                        <span>7 1/2</span>
                    </div>
                    <div class="list-size" data-size="8">
                        <span>8</span>
                    </div>
                    <div class="list-size" data-size="4">
                        <span>8 1/2</span>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
    
</template>

<script>
    /*import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'*/
    export default {
        components: { /*VueSlickCarousel*/ },
        props: ['product', 'productIndex'],
        data() {
            return{
                isHovering: false,
                /*settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }*/
            }
        },
        mounted: function() {
            // console.log(this.product);
        },
        computed: {
            baseUrl(){
                return process.env.MIX_BASE_URL;
            }
        },
        methods: {
            toggleHover: function(val) {
                this.isHovering = val;
            },
            //  onHover() {
                
            //     $('.product').hover(function() {
            //             let product_data = $(this).attr('id');
            //             $(this).find('.text-box').addClass('active-text-box');
            //             $(this).find('.img-box').attr('style', 'background-image: url('+ public_path +'img/product/background-product.png);')
            //         }, function() {
            //             $('.size-box').removeClass('active');

            //             let data_active = $(this).find('.add-bag').attr('data-active');
            //             let product_data = $(this).attr('id');

            //             if (data_active == "none") {
            //                 $(this).find('.text-box').removeClass('active-text-box');
            //                 $(this).find('.img-box').attr('style', "background-image: url('"+ public_path +"/img/product/"+ product_data +".png');")
            //             } else {
            //                 $(this).find('.text-box').removeClass('active-text-box');
            //                 // $(this).find('.size-box').removeClass('active');
            //                 $(this).find('.img-box').attr('style', "background-image: url('"+ public_path +"/img/product/"+ product_data +".png');")
            //             }

            //         }, 3000);

            //     $('.add-bag').on('click', function() {
            //         var data_product = $(this).attr('data-product');
            //         var data_aria = $(this).attr('data-aria');
            //         var data_size = $(this).attr('data-size');

            //         if (data_size == "none") {
            //             if (data_aria == "false") {
            //                 $(this).attr('data-aria', 'true');
            //                 $(this).attr('data-active', 'true');

            //                 // Make Permanent Hover
            //                 $('.product .text-box').removeClass('active-text-box')
            //                 $('#' + data_product + " .text-box").addClass('active-text-box')

            //                 // Make Active Size Box
            //                 $('.product .size-box').removeClass('active')
            //                 $('#' + data_product + " .size-box").addClass('active');

            //                 $(this).find('.img-box').attr('style', "background-image: url('"+ public_path +"img/product/background-product.png');")					

            //                 $('.list-size').on('click', function() {

            //                     $('.list-size').removeClass('active');
            //                     $(this).addClass('active');

            //                     var data_size_ring = $(this).attr('data-size');
            //                     var data_size = data_size_ring;
            //                     $('#' + data_product + " .add-bag").attr('data-size', data_size_ring);

            //                     // Make Full Button Add Bag
            //                     $('.product .text-box .bottom-text .right-content').removeClass('active-size')
            //                     $('#' + data_product + " .text-box .bottom-text .right-content").addClass('active-size')
            //                 })

            //                 console.log(data_aria)
            //             } else {
            //                 $(this).attr('data-aria', 'false');
            //                 $(this).attr('data-active', 'none');
            //                 $('.product .text-box .bottom-text .right-content').removeClass('active-size')
            //                 $('.product .size-box').removeClass('active')
            //                 console.log(data_aria)
            //             }
            //         } else {

            //             // Add To Cart
            //             console.log(data_size)
            //             $('.product .text-box .bottom-text .right-content').removeClass('active-size')
            //             $('.product .text-box').removeClass('active-text-box')
            //             $('.product .size-box').removeClass('active')
            //             console.log(data_product)
            //             $('#'+ data_product +' .img-box').attr('style', "background-image: url('"+ public_path +"/img/product/"+ data_product +".png');")
            //         }
            //     });

            //     $('.love-icon').on('click', function(event) {
            //         let status_aria = $(this).attr('aria-status');
            //         let data_product = $(this).attr('love-product');

            //         if (status_aria == "false") {
            //             $(this).attr('aria-status', 'true');
            //             $('#love-'+ data_product +' .fas.fa-heart').css('display','block');
            //             $('#love-'+ data_product +' .far.fa-heart').css('display','none');
            //         } else {
            //             $(this).attr('aria-status', 'false');
            //             $('#love-'+ data_product +' .fas.fa-heart').css('display','none');
            //             $('#love-'+ data_product +' .far.fa-heart').css('display','block');
            //         }
            //     });

            // }
        }
            
    }
</script>

<style lang="scss" scoped>

</style>